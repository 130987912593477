@tailwind components;
@tailwind utilities;

body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.ant-menu .ant-menu-item-selected {
  /* box-shadow: -4px 5px 6px 0px #d0d1d4; */
  border-right: 3px solid #fffdf9;
}
.ant-layout .ant-layout-sider {
  overflow-y: auto;
}
::-webkit-scrollbar {
  width: 4px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.ant-form-item {
  margin-bottom: 10px;
}
.ant-btn-primary {
  background-color: #145262 !important;
}
.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: #145262 !important;
  border-color: #145262 !important;
}
.ant-modal-confirm .ant-modal-confirm-paragraph {
  row-gap: 0px !important;
}
.ant-menu-item-selected .ant-menu-title-content {
  color: #145262;
}
.ant-menu-item-selected .ant-menu-item-icon {
  color: #145262 !important;
}
.slick-dots.slick-dots-bottom li button {
  background: #145262 !important;
  margin-top: 10px;
}
th.ant-table-cell::before {
  width: 0 !important;
}
.custom-header .ant-spin-container .ant-table-content .ant-table-thead .ant-table-cell {
  background: #fff !important;
  color: #000 !important;
}
.ant-table-thead th.ant-table-column-sort,
.ant-table-column-has-sorters {
  background: #185262 !important;
}
.ant-table-column-sorter {
  color: #fff !important;
}

.react-form-builder .react-form-builder-toolbar {
  margin-top: -35px !important;
  max-height: calc(100vh - 225px) !important;
  overflow-y: scroll !important;
}

.react-form-builder .react-form-builder-preview {
  min-height: auto !important;
  max-height: calc(100vh - 250px) !important;
  overflow-y: scroll !important;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-upload-picture > div {
  width: 100% !important;
  border-width: 0px !important;
}
