@font-face {
  font-family: "Roboto-Thin";
  src: url("Roboto-Thin.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto-Light";
  src: url("Roboto-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto-Regular";
  src: url("Roboto-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto-Medium";
  src: url("Roboto-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto-Bold";
  src: url("Roboto-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto-Black";
  src: url("Roboto-Black.ttf") format("truetype");
}
.font-roboto-thin {
  font-family: "Roboto-Thin";
}
.font-roboto-light {
  font-family: "Roboto-Light";
}
.font-roboto-regular {
  font-family: "Roboto-Regular";
}
.font-roboto-medium {
  font-family: "Roboto-Medium";
}
.font-roboto-bold {
  font-family: "Roboto-Bold";
}
.font-roboto-black {
  font-family: "Roboto-Black";
}
